// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './app/App';
// // import App from './app/_App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.css";
const rootEl = document.getElementById("root");

ReactDOM.render(<App />, rootEl);

const _module: any = module

if (_module.hot) {
    _module.hot.accept("./app/App", () => {
    const NextApp = require("./app/App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}