import MsalConfig from './user/msal/config';
import LionConfig from './user/lion/config';
import { User } from './lib/user';
import { HttpClient } from './user/common';

// import * as LionUserManager from './user/lion/manager';
// import * as LionUserAuth from './user/lion/manager';

// import * as MsalUserManager from './user/msal/manager';

export * from './user/lion/manager';
export * from './user/lion/auth';

export * from './user/msal/manager';


export default User;

const createMsalConfig = () => new MsalConfig();
const createLionConfig = (client: HttpClient) => new LionConfig(client);

export const DefaultConfigs = {
    createMsalConfig,
    createLionConfig
}
